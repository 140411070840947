/* Accordion container */
.accordion {
    margin: 0 auto; /* Center the accordion horizontally */
    padding: 20px;
    padding-bottom: 20vh;
  }

  .accordion h1 {
    font-family: 'Arial', sans-serif; /* Change to Arial font */
    font-weight: bold; /* Ensure boldness */
    font-size: 4.5vh; /* Larger font size */
    color: #fffffffb; /* Red */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Add text shadow */
    margin-bottom: 10px;
    text-align: center;
  }
  
  /* Accordion title */
  .accordion-heading {
    position: relative; /* Set relative position */
    display: flex; /* Use flexbox */
    align-items: center; /* Center vertically */

    padding: 10px;
    margin: 0;
    cursor: pointer;
    border-bottom: 1px solid #ccc; /* Add underline */
    
  }

  

  .accordion-title{
    display: flex;
  }
  /* Accordion title */
.accordion-title {
    padding: 10px;
    margin: 0;
    cursor: pointer;
    transition: color 0.3s; /* Add transition effect for color change */
    color: white;
  }
  
  /* Accordion item */
.accordion-item:hover .accordion-title {
    color: #ffc74f; /* Change color on hover */
  }
  

  
  /* Active state for title */
  .accordion-item.active .accordion-title {
    color: #ffc74f; /* Change color on hover */
  }

  .accordion-item.active .sign {
    color: #ffc74f; /* Change color on hover */
  }

  .bullet{
    margin-right: 5vh;
    font-size: 1.5vh;
    padding-top:0.3vh ;
  }
  
  /* Active state for title */
  .accordion-heading.active {
    color: #0c01aa; /* Change color for active state */
  }
  
  /* Accordion heading */
  .accordion-heading {
    display: flex; /* Use flexbox */
    justify-content: space-between; /* Align items with space between them */
    align-items: center; /* Center vertically */
  }
  
  /* Project description */
  .project-description {
    width: 79vh;
    margin-right: 10vh;
    color: #9c9c9c;
  }
  
  
  
  
  
  /* Accordion image */
  .accordion-image {
    width: 80%; /* Make image responsive */
    height: 20vh;
    
  }
  
  
  /* Project description */
  .project-description {
    
    overflow: hidden; /* Hide overflow */
    transition: max-height 0.5s; /* Add transition for smooth animation */
  }

  .accordion-description-container{
    margin-left: 10vh; /* Push the description to the right */
    margin-right: 10vh;
    max-height: 450px; /* Set the maximum height */
  }
  
  /* Short description when inactive */
  .project-description.short {
    max-height: 4vh; /* Set a shorter height */
  }
  


  /* Plus and minus sign */
  .sign {
    position: absolute; /* Set absolute position */
    right: 10px; /* Align to the right */
    top: 50%; /* Align vertically */
    transform: translateY(-50%); /* Center vertically */
    font-size: 4vh;
    font-weight: bold;
    color:white
  }
  @media (max-width: 768px) {
    .bullet {
      display: none; /* Hide the entire accordion on smaller screens */

      }
    .accordion {
      display: block; /* Display the accordion as a block on smaller screens */
    }
  
    .accordion h1 {
      font-size: 3vh; /* Adjust heading font size for smaller screens */
    }
  
    .accordion-heading {
        font-size: 8px;
    }
  
    .accordion-heading.active {
      color: #584dff; /* Adjust color for active state on smaller screens */
    }
  
    .project-description {
      width: auto; /* Make the description width auto for smaller screens */
      margin: 10px 0; /* Add margin to the description on smal ler screens */
    padding-left: 30px;
    padding-right: 20px;
    }
  
    .accordion-description-container {
      margin: 0; /* Reset margin for the description container on smaller screens */
      max-height: none; /* Remove max height for the description container on smaller screens */
    }
  
    .sign {
        font-size: 12px;
    }
  }
  