.intro-container {
    position: relative; /* Set position to relative */
    display: flex;
    justify-content: space-between;
    align-items: flex-end; /* Align items to the bottom */
    margin: 0 auto; /* Center the container */
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.7); /* Strong shadow at the bottom */
    margin-bottom: 0px;
    background: #333333;
    text-align: justify;
    height: 86vh; /* Adjusted height */
}

.left-section {
    flex: 1; /* Use flex to grow and shrink */
    text-align: right; /* Align text to the right */
    padding-right: 31vh; /* Remove right padding */
}
.right-section {
    flex: 1; /* Larger width for content */
    max-height: 550px; /* Limit height of right section */
    padding-left: 0vh;
    text-align: right;
    animation-name: slideInRight;
    animation-duration: 1s;
    display: flex;
    flex-direction: column; /* Align items vertically */
    justify-content: flex-end!important; /* Align items to the bottom */
    align-items: flex-end!important; /* Align items to the bottom horizontally */
}


@keyframes slideInRight {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.my-name {
    font-family: 'Arial', sans-serif; /* Change to Arial font */
    font-weight: bold; /* Ensure boldness */
    font-size: 7vh; /* Adjust font size */
    color: #fa9d56; /* Orangey-brown */
    margin: 0; /* Remove margins */
    margin-left: 2vh;
}

.intro-container p {
    font-family: 'Arial', sans-serif; /* Change to Arial font */
    font-size: 4vh; /* Adjust font size */
    font-weight: bold;
    color: #ffffff; /* Light gray */
    line-height: 1.5; /* Increase line height for better readability */
    margin: 0; /* Remove margins */
}

.intro-container img {
    width: 85vh; /* Make image responsive */
    height: auto; /* Maintain aspect ratio */
}
.image-container {
    position: absolute;
    bottom: 0;
}



.intro-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20px 5% 2vh 5%;
    margin: 0 auto;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.7);
    margin-bottom: 0px;
    background: #333333;
    text-align: justify;
    height: 86vh;
    overflow: hidden; /* Hide overflow to prevent vertical scroll */
}

.right-section {
    flex: 1;
    max-height: 550px;
    padding-left: 0vh;
    position: relative; /* Set position to relative */
}

.contact-icons {
    position: absolute;
    bottom: 0;
    right: -20px; /* Adjust the distance from the right side */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.contact-icons img {
    width: 30px;
    height: auto;
    margin-bottom: 10px;
}


@media (max-width: 768px) {
    
    .intro-container {
        flex-direction: column;
        padding: 10px; /* Adjust padding for smaller screens */
        overflow-y: hidden;
        font-size: 8px;
    }

    .left-section,
    .right-section {
        flex: none; /* Remove flex grow */
        width: 100%; /* Full width for mobile */
    }

    .right-section {
        padding-left: 0; /* Remove left padding */
        text-align: left; /* Center-align content */
        height: auto;
        overflow: auto;
        margin-top: 100px;
        text-align: right;

    }

    .right-section img {
        width: 150px; /* Reduce image size for mobile */
        height: auto;
        margin-bottom: 10px; /* Add some space below the image */
    }

 
    .my-name {
        font-size: 5vh ; /* Adjust font size with !important */
    }
    .intro-container p {
        margin-right: 0; /* Remove right margin for paragraphs */
        margin-bottom: 0;
        font-size: 2.5em;
    }

 
    .intro-container img {
        width: 400px; /* Make image responsive */
        height: auto; /* Maintain aspect ratio */
    }
}
