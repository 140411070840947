.navbar {
  position: fixed; /* Change position to fixed */
  top: 0;
  left: 0;
  width: 100%; /* Make navbar full-width */
  background-color: transparent; /* Initially transparent */
  padding: 15px 30px;
  z-index: 1000;
  display: flex;
  justify-content: center; /* Center-align items */
  align-items: center;
  transition: background-color 0.3s ease; /* Add transition */
  box-shadow: none; /* Remove initial shadow */
  padding-top: 25px;
  padding-bottom: 25px;

}

.navbar.scrolled {
  background-color: #ffa44e; /* Orange color when scrolled */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow on scroll */
}

.nav-toggle {
  display: none;
}

.nav-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nav-list li {
  margin: 0 15px; /* Adjusted margin */
}

.nav-list li button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 2.1vh; /* Adjusted font size */
  color: #fff;
  transition: color 0.3s ease;
}

.nav-list li button:hover {
  color: #e6ff57;
}

@media (max-width: 768px) {
  .navbar {
    justify-content: space-between; /* Align toggle and contact info */
  }

  .nav-list {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: absolute;
    top: calc(100% + 15px);
    left: 0;
    background-color: #ffb066; /* Orange background color */
    border-radius: 5px; /* Add border radius */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow */
  }

  .nav-list.open {
    display: flex;
  }
  .nav-list li {
    padding: 10px;
  }

  .nav-toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    cursor: pointer;
  }

  .nav-toggle div {
    width: 100%;
    height: 3px;
    background-color: #fff;
    transition: all 0.3s ease;
  }

  .contact-info {
    display: none; /* Hide contact info on small screens */
  }
}
