.project-grid-container h3 {
  font-family: 'Arial', sans-serif; /* Change to Arial font */
  font-weight: bold;
  font-size: 4.5vh;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgb(0, 0, 0);
  margin-bottom: 10px;
  text-align: center;
}

.project-grid-container {
  margin-top: 0px;
  background-color: #1a1a1a00;
}

.project-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 0 auto;
  max-width: 1200px;

}

.project-card {
  background-color: #232533;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  width: calc(33.33% - 20px); /* Display three cards per row on wider screens */
}



.project-card img {
  height: 20vh;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0; /* Adjust border radius */
}

.project-details {
  padding: 20px;
  padding-left: 35px;
  padding-right: 35px;
  background-color: #2c2c2c00;
  overflow: auto;
  height: 250px;

}

.project-details h3 {
  margin-top: 0;
  color: #b6b6b6;
  font-size: 20px;
}

.project-details p {
  margin-bottom: 15px;
  color: #ebebeb;
}

.project-details button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #304c69;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: calc(100% - 40px);
}

.project-details button:hover {
  background-color: #308ff5;
}

@media (max-width: 786px) {
  .project-card {
    width: calc(50% - 10px); /* Display three cards per row on wider screens */
  
  }
  .project-card img {
    height: 10vh;
    width: 100%;
    object-fit: cover;
    border-radius: 10px 10px 0 0; /* Adjust border radius */
  }
  
  .project-details {
    padding: 0; /* Remove padding */
    background-color: #f9f9f900;
    overflow: auto;
    height: 200px;
  }
  
  .project-details h3 {
    margin-top: 0;
    font-size: 10px;
  }
  
  .project-details p {
    margin-bottom: 0; /* Remove margin bottom */
    color: #666;
    font-size: 10px;
    padding-left: 5px;
    padding-right: 5px;

  }
}
