body {
  font-family: 'Roboto', sans-serif; /* Set default font family */
  color: #000000; /* White text color */
  background-color: #324a66; /* Even darker background */
}

.home--page {
  margin: auto !important;

  background: #1c2127;
}




@media (max-width: 768px) {
  .home--page {
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .nav-list li button {
    font-size: 14px; /* Further reduce font size for very small screens */
  }

  .contact-info {
    display: block; /* Show contact info on small screens */
    text-align: center; /* Center align contact info */
    margin-top: 10px; /* Add some space between navigation and contact info */
  }

  .contact-info a {
    margin-left: 0; /* Reset left margin for contact info links */
  }
}


