.skills-section {
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 50px;
    margin-bottom: 0;
    background-color: #1a1a1a;

  }
  
  .about-me {
    padding-bottom: 0;
    overflow-y: auto; /* Use auto to show scrollbar only if needed */
    animation-name: slideInRight;
    animation-duration: 1s;
    padding-left: 24vh;
    padding-right: 25%;
    text-align: left;
  }
  
  @keyframes slideInRight {
    from {
      opacity: 0;
      transform: translateX(100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* Width */
  
  .about-me h2 {
    font-family: 'Arial', sans-serif; /* Change to Arial font */
    font-weight: bold; /* Ensure boldness */
    font-size: 4.5vh; /* Larger font size */
    color: #ffffff; /* Dark gray */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Add text shadow */
    margin-bottom: 10px;
    display: flex;
    margin-left: 0vh;
  }
  
  .line {
    margin-right: 2.5vh;
  }
  
  .about-me h3 {
    font-family: 'Arial', sans-serif; /* Change to Arial font */
    font-weight: bold; /* Ensure boldness */
    color: #ffffff; /* Light gray */
  }
  
  .about-me p {
    font-family: 'Josefin Sans', sans-serif; /* Modern sans-serif font for paragraphs */
    font-size: 2.5vh; /* Larger font size */
    color: #ffffff; /* Medium gray */
    line-height: 1.5; /* Increase line height for better readability */
    margin-top: 0;
    text-indent: 0vh; /* Adjust the indent size as needed */
    display: flex;
  }
  
  .skill-container {
    max-width: 200vh;
    margin: 0 auto; /* This centers the container horizontally */
  }
  
  .skills {
    font-family: 'Arial', sans-serif; /* Change to Arial font */
    color: #ffffff; /* Light gray */
    font-size: 2em;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(73, 73, 73, 0.2); /* Add text shadow */
  }
  
  .skill-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-left: 12vh;
    margin-right: 12vh;
  }
  
  .skill-box {
    text-align: center;
    background-color: #474545; /* Dark gray */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .skill-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    background-color: #fac656; /* Orangey-brown */
  }
  
  .skill-box span {
    display: block;
    font-size: 1.2em;
    font-weight: bold;
    color: #ddd; /* Light gray */
  }
  
  @media (max-width: 768px) {
    .skills-section {
      padding-bottom: 20px; /* Reduce bottom padding for better spacing on small screens */
    }
  
    .about-me {
      padding-bottom: 0; /* Remove bottom padding */
      overflow-y: auto; /* Use auto to show scrollbar only if needed */
      animation-name: slideInRight;
      animation-duration: 1s;
      padding-left: 5%;
      padding-right: 5%; /* Reduce right padding for better centering on small screens */
    }
  
    .about-me h2 {
      font-size: 3vh; /* Reduce font size for headings on small screens */
      margin-left: 0; /* Reset left margin */
    }
  
    .line {
      display: none; /* Hide the line separator on small screens */
    }
  
    .about-me h3 {
      font-size: 2vh; /* Reduce font size for subheadings on small screens */
    }
  
    .about-me p {
      font-size: 1.5vh; /* Reduce font size for paragraphs on small screens */
    }
  
    .skill-container {
      max-width: none; /* Remove maximum width */
      margin: 0; /* Reset margin */
    }
  
    .skills {
      font-size: 2em; /* Adjust font size for headings on small screens */
      text-align: center; /* Center align text */
    }
  
    .skill-grid {
      margin-left: 0%; /* Adjust left margin for better alignment on small screens */
      margin-right: 0%; /* Adjust right margin for better alignment on small screens */
    }
  
    .skill-box {
      margin: 0!important;
    }
  }
  