.skill-box {
    flex: 1 1 200px; /* Each skill box will take up equal space */
    padding: 10px;
    border-radius: 25px;
    text-align: center;
    height: 30vh; /* Set the height of the skill box */
    width: 30vh;
    margin-bottom: 0;

}


.skill-box img {
    width: 10vh;
    height: 10vh;
    object-fit: contain;
    transition: all 0.3s ease; /* Smooth transition on hover */
    margin-top: 5vh;
}


.skill-box span {
    display: block;
    margin-top: 1vh;
    font-weight: bold;
    font-size: 1.8vh;
    margin-top: 2vh;
    transition: all 0.3s ease; /* Smooth transition on hover */
    margin-bottom: 0;

}

@media (max-width: 768px) {
    .skill-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
      padding: 0 10px; /* Add padding to the sides for better spacing */
    }
  
    .skill-box {
      width: 13vh;
      margin-top: 20px;
      height: 16vh; /* Adjust height of skill boxes */
      padding: 15px; /* Add padding to the skill boxes for better spacing */
      text-align: center; /* Center align text */
      box-sizing: border-box; /* Include padding and border in the element's total width and height */
    }
  
    .skill-box img {
      width: 30px; /* Adjust width of skill box images */
      height: 30px; /* Adjust height of skill box images */
      margin: 0 auto 0px; /* Center align images and add bottom margin */
    }
  
    .skill-box span {
      font-size: 0.5em; /* Adjust font size of text within skill boxes */
    }
  }
  